.login-container {
    text-align: center;
    height: 100vh;
    padding-top: 100px;
    display: flex;
    justify-content: center;
  }
  
  .login-container .login {
    width: 350px;
  }
  
  .login .branding {
    margin-bottom: 30px;
  }
  
  .branding .logo {
    width: 250px;
    height: 70px;
    margin-bottom: 30px;
  }
  
  .branding .text {
    font-weight: bold;
    font-size: large;
  }
  
  .login-btn {
    margin-top: 20px;
    width: 100%;
  }